import { createApp, defineAsyncComponent } from "vue";
import emitter from "./script/vuetils/event-bus";

if (document.querySelector(".js-vue--contents-basket-view")) {
  const component = defineAsyncComponent(() => import("./vue-components/ContentsBasketView.vue"));
  createApp(component).provide("emitter", emitter).mount(".js-vue--contents-basket-view");
}

if (document.querySelector(".js-vue--details-basket-view")) {
  const component = defineAsyncComponent(() => import("./vue-components/DetailsBasketView.vue"));
  createApp(component).provide("emitter", emitter).mount(".js-vue--details-basket-view");
}
