// TODO try async/await
import axios from "axios";
import $ from "jquery";
import * as axiosX from "./http/axios-extensions";
import $$ from "./utils/double-dollar";
import "./utils/array-extensions";

type ProductPriceResultDto = {
  productId: number;
  price?: string;
  retailPrice?: string;
  isAvailable: boolean;
  requestedQuantityUnit: string;
};

export default class ProductPricing {
  static init() {
    const pricingElements = $$(".nt-product-pricing", (el) => el);
    const stockElements = $$(".nt-product-stock", (el) => el);
    const unitElements = $$(".nt-product-unit", (el) => el);

    const elementsArray = [...pricingElements, ...stockElements, ...unitElements];
    if (elementsArray.length == 0) {
      return;
    }

    const includeRetailPrice = document.querySelectorAll(".nt-product-pricing__retail").length > 0;

    const productIdList = elementsArray
      .map((el) => {
        return parseInt(el.getAttribute("data-productid") || "0");
      })
      .distinct();
    ////.filter((n) => n > 500000); //-- add this to test "missing" prices

    this.getProductPrices(productIdList, includeRetailPrice, (results) => {
      elementsArray.forEach((el) => {
        // set pricing layout
        const el$ = $(el);
        el$.removeClass("nt-product-pricing--loading");

        const productId = parseInt(el$.data("productid") || "0");
        if (productId > 0 && results) {
          const productResult = results.filter((r) => r.productId === productId)[0];
          if (productResult && !!`${productResult.price}`) {
            const priceElement = el$.find(".nt-product-pricing__price");
            priceElement.html(productResult.price || "");

            if (productResult.retailPrice != "") {
              el$.find(".nt-product-pricing__retail").removeClass(" nt-hidden");
              const retailPriceElement = el$.find(".nt-product-pricing__retail-price");
              retailPriceElement.html(productResult.retailPrice || "");
            }

            el$.find(".nt-product-stock__availability-value").val(productResult.isAvailable.toString());
            if (productResult.isAvailable) {
              el$.find(".nt-product-stock__unavailable").hide();
              el$.find(".nt-product-stock__available").show();
              el$.find(".nt-product-stock__enable-when-available").removeAttr("disabled");
            } else {
              el$.find(".nt-product-stock__unavailable").show();
              el$.find(".nt-product-stock__available").hide();
              el$.find(".nt-product-stock__enable-when-available").prop("disabled", true);
            }

            if (!!productResult.requestedQuantityUnit) {
              const requestedQuantityUnitElement = el$.find(".nt-product-unit");
              requestedQuantityUnitElement.text(productResult.requestedQuantityUnit);
            }

            setEnabledFormElements(el$, true);
            return;
          }
        }

        el$.addClass("nt-product-pricing--failed");

        setEnabledFormElements(el$, false);
      });
    });

    function setEnabledFormElements(el$: JQuery<any>, enabled: boolean) {
      // disable add to basket
      const formObjects$ = el$.closest(".js-product").find("button, input");
      formObjects$.toggleClass("disabled", !enabled).prop("disabled", !enabled);
    }
  }

  private static getProductPrices(
    productIdList: Array<number>,
    includeRetailPrice: boolean,
    successCallback: (result: Array<ProductPriceResultDto>) => void,
  ) {
    const url = `/api/pricing/fetch?includeRetailPrice=${includeRetailPrice ? "true" : "false"}`;
    axios
      .post(url, productIdList)
      .then((result) => {
        console.debug(result);
        successCallback(result.data);
      })
      .catch(axiosX.defaultCatch);
  }
}
