import { Collapse } from "bootstrap";
import $ from "jquery";
import SiteApp from "./site";
import $$ from "./utils/double-dollar";
import ProductPricing from "./product-pricing";

class ProductList {
  private lastChangedPropertyId = 0;
  private lastCheckedPropertyId = 0;
  private mobileFilterVisible?: boolean;
  checkCollapseFilters = this.debounce(
    () => {
      const newState = $("#collapseFiltersTrigger:visible").length === 1;
      if (newState !== this.mobileFilterVisible) {
        if (newState) {
          // $("#collapseFilters").collapse("hide");
          $$("#collapseFilters", (el) => {
            let collapse = new Collapse(el);
            collapse.hide();
          });
          // $("#collapseFilters div.collapse").collapse("hide");
          $$("#collapseFilters div.collapse", (el) => {
            let collapse = new Collapse(el);
            collapse.hide();
          });
        } else {
          // $("#collapseFilters").collapse("show");
          $$("#collapseFilters", (el) => {
            let collapse = new Collapse(el);
            collapse.show();
          });
        }

        this.mobileFilterVisible = newState;
      }
    },
    50,
    false,
  );

  constructor() {}

  init = () => {
    this.checkCollapseFilters();

    // ReSharper disable once Html.EventNotResolved
    window.addEventListener("popstate", (): void => {
      // revert filters
      this.loadPage(document.location.href, false);
    });

    window.onresize = this.checkCollapseFilters;

    $("#productlist-container").on("click", "a.js-ajaxpage", (event) => {
      event.preventDefault();
      event.stopImmediatePropagation();
      // take currentTarget, is always the a tag (even when clicking on icon)
      const url = $(event.currentTarget).attr("href");
      if (typeof url === "undefined") {
        return false;
      }
      this.loadPage(url, true);
      return true;
    });

    const $searchForm = $("#search-form");
    $searchForm.on("submit", (event) => {
      const form$ = $(event.target);
      event.preventDefault();
      event.stopImmediatePropagation();
      const url = form$.attr("action") + "?" + form$.serialize();
      this.loadPage(url, true);
      return true;
    });

    $searchForm.on("change", "input:checkbox", (event) => {
      const $form = $(event.delegateTarget);
      this.lastChangedPropertyId = $(event.target).parents("section").data("property-id");

      this.lastCheckedPropertyId = 0;
      if (event.target.checked) {
        this.lastCheckedPropertyId = this.lastChangedPropertyId;
      }

      $form.trigger("submit");
      // $(event.delegateTarget)
      //   .find("form")
      //   .submit();

      this.checkFilterReset();

      if (this.mobileFilterVisible) {
        $(".nt-results-toast").toast("show");
      }
    });

    const $toasts = $(".nt-results-toast");
    // overrule display to prevent that toastr is invisibly (opacity 0) above another html object (eg filter btn)
    $toasts.on("hide.bs.toast", function () {
      this.style.display = "none";
    });

    $toasts.on("show.bs.toast", function () {
      this.style.display = "block";
    });

    const $collapseFilters = $("#collapseFilters");
    $collapseFilters.on("show.bs.collapse", function (e) {
      if ($(this).is(e.target)) {
        SiteApp.smoothScrollTo(".nt-header");
      }
    });

    $collapseFilters.on("hide.bs.collapse", function (e) {
      if ($(this).is(e.target)) {
        SiteApp.smoothScrollTo(".nt-header");
      }
    });
  };

  checkFilterReset() {
    $("#reset-filters").toggleClass("d-none", $("#productlist-container :checked").length === 0);
  }

  initlastCheckedPropertyId() {
    this.lastCheckedPropertyId = $("#productlist-container :checked:first").parents("section").data("property-id");
  }

  loadPage(url: string, push: boolean) {
    ////console.log(`loading ${url}`);

    // create another url to prevent caching in the browser
    // url in the history (pushState) may not match the requested url (without layout)
    let ajaxUrl = "";
    if (typeof url !== "undefined" && url !== "") {
      ajaxUrl =
        url + (url.indexOf("?") !== -1 ? "&" : "?") + "ajax=1&lastCheckedPropertyId=" + this.lastCheckedPropertyId;
    }

    if (ajaxUrl === "") {
      return;
    }

    const self = this;

    $.ajax({
      url: ajaxUrl,
      headers: { "X-AJAXPAGE": "1" },
      success(data, status, xhr) {
        const fullData$ = $("<div/>").append(data);

        // replace every filter/section in our existing html, except the one that was last changed
        const sections = $("#productlist-container").find("section[data-property-id]");
        for (let i = 0; i < sections.length; i++) {
          const thisSection$ = $(sections[i]);
          const propertyId = thisSection$.data("property-id");
          const propertyFilterType = thisSection$.data("property-filter-type");

          ////console.log("> section[" + i + "] prop id " + propertyId);
          ////console.log("> section[" + i + "] prop filter type " + propertyFilterType);
          if (self.lastChangedPropertyId === propertyId && propertyFilterType === 2) {
            ////console.log(`> ${propertyId} is last changed, not swapping`);
            continue;
          }

          const isExpanded = $("[data-toggle=collapse]", thisSection$).attr("aria-expanded") === "true";
          const sectionSelector = "section[data-property-id='" + propertyId + "']";
          const newElementHtml = fullData$.find(sectionSelector).html() || "";

          ////console.log("> finding " + sectionSelector + " in new html");
          if (thisSection$.data("ajax-updateable") === true) {
            thisSection$.html(newElementHtml);
          }

          if (!isExpanded) {
            // call hide and show prevents the collapsing animation from showing
            thisSection$.hide();
            // $(".collapse", thisSection$).collapse("hide");
            $(".collapse", thisSection$).each((index: Number, element: HTMLElement) => {
              let collapse = new Collapse(element);
              collapse.hide();
            });
            thisSection$.show();
          }
        }

        // swap products block content
        const newAjaxContent$ = fullData$.find("#ajax-content");
        $("#ajax-content").html(newAjaxContent$.html());

        SiteApp.initAutoLink();
        ProductPricing.init();

        const title = xhr.getResponseHeader("X-AJAXPAGETITLE");
        if (title) {
          document.title = decodeURIComponent(title).replace(/\+/g, " ");
        }

        if (push) {
          history.pushState(null, title ?? "", url);
        }

        //ProductPricing.init();
      },
    });
  }

  // https://davidwalsh.name/javascript-debounce-function
  debounce(func: Function, wait: number, immediate: boolean) {
    let timeout: number;
    const context = this;
    return function () {
      let args = arguments;
      const later = () => {
        timeout = 0;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = window.setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
}

const productList = new ProductList();
productList.init();
