import $ from "jquery";
import axios from "axios";
import head from "lodash-es/head";

import * as axiosX from "./http/axios-extensions";
import Autocomplete, { AutocompleteOptions } from "./components/bootstrap-5-autocomplete";
import $$ from "./utils/double-dollar";

interface SearchResult {
  id: number;
  encryptedId: string;
  description: string;
  dataLayerProduct: any;
}

class ProductQuickEntry {
  autocomplete: Autocomplete;
  $productQuickEntry: JQuery<Element>;
  cachedResults: SearchResult[];

  constructor(element: Element) {
    this.$productQuickEntry = $(element);
    this.cachedResults = [];
    const $form = this.$productQuickEntry.parents("form");

    let options: AutocompleteOptions = {
      source: () => {
        return this.getSuggestions();
      },
      onSelectItem: (item, element) => {
        const cachedResult = head(this.cachedResults.filter((result) => result.id === parseInt(item.value)));
        $form.data("pid", cachedResult?.encryptedId ?? "");
        $form.data("datalayerCartItem", JSON.stringify(cachedResult?.dataLayerProduct));
        $form.find(`input[name="quantity"]`).trigger("focus").trigger("select");
      },
      highlightClass: "text-success",
      treshold: 0,
      maximumItems: 10,
    };

    this.autocomplete = new Autocomplete(element, options);

    $form.on("add-to-basket", () => {
      console.log("catching form 'submit' in product quick entry");
      this.autocomplete.clearEntry();
      this.autocomplete.focus();
    });
  }

  private getSuggestions() {
    const input = this.$productQuickEntry.val();
    if (input === "") {
      return Promise.resolve();
    }

    return axios
      .get(`/searchsuggestions/?q=${input}`)
      .then((response) => {
        this.cachedResults = response.data as SearchResult[];
        let dict: any = {};
        this.cachedResults.forEach((el) => (dict[el.description] = el.id));
        return dict;
      })
      .catch(axiosX.defaultCatch);
  }
}

$$(".js-product-quick-entry", (el) => new ProductQuickEntry(el));
