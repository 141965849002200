// declare var gtag: any; // gtag is only for universal analytics, not for Google Tag Manager (uses dataLayer array)

// // ReSharper disable once InconsistentNaming
// var Multilang = Multilang || {};
// // ReSharper disable once InconsistentNaming
// var Global = Global || { CultureCode: null, LanguageCode: null };

import { Collapse } from "bootstrap";
import $ from "jquery";
import $$ from "./utils/double-dollar";

/**
 * Initially copied from site.ts in B2C project.
 */
export default class SiteApp {
  static init() {
    $(".hide.onload").hide().removeClass("hide").removeClass("onload");

    // Prevent caching of dropdownlist when refreshing
    $("select").attr("autocomplete", "off");

    this.initAutos();
    this.initToggleSubmit();
    this.initScrollToTop();
    this.initCookieNotice();

    $("[data-toggle=smooth-scroll]").on("click", (e) => {
      const el = e.target as any as HTMLElement;
      this.smoothScrollTo(el.getAttribute("href") ?? "", parseInt(el.getAttribute("data-offset") ?? ""));
      e.preventDefault();
    });

    // ****** START js to make mobile menu work
    // when opening the sidebar by clicking on btn menu
    $("#nt-header__btn-to-open-mobile-sidebar-menu").on("click", () => {
      this.mobileMenuToggle();
      $(".collapse.in").toggleClass("in");
    });

    // if close btn in sidebar or overlay was clicked
    $("#nt-header__mobile-sidebar-menu-btn--close").on("click", () => {
      this.mobileMenuToggle();
    });
    // ****** END js to make mobile menu work

    const $collapse = $(".nt-header__mobile-search--collapse");
    $collapse.on("shown.bs.collapse", () => {
      $("#nt-search-form-mobile__form-input").trigger("focus");
    });

    $collapse.on("show.bs.collapse", () => {
      // make x (close btn next to search icon btn) is invisible beneath overlay
      $(".nt-header__mobile-search__toggle-link").hide();
    });

    // when clicking anywhere on overlay collapse (except on input), collapse overlay will close
    $collapse.on("click", (evt) => {
      if (evt.target.id === "nt-search-form-mobile__form-input") return;
      // also for descendants being clicked
      if ($(evt.target).closest("#nt-search-form-mobile__form-input").length) return;

      // Add "collapsed" class (prevents X button showing up beside the search icon) and show search icon
      const $toggleLink = $(".nt-header__mobile-search__toggle-link");
      $toggleLink.addClass("collapsed");
      $toggleLink.show();

      // hide the overlay
      $(evt.currentTarget).removeClass("show");
    });

    // collapse on product-detail (properties): when opening one, the others should all close
    // when double clicking on one it should still be shown
    $(".nt-product-detail__variant-btn").on("click", function () {
      const $clickedElement = $(this);
      // don't do this when only 1 btn
      const $variantBtn = $(".nt-product-detail__variant-btn");
      const amountOfVarButtons = $variantBtn.length;
      if (amountOfVarButtons === 1) {
        return;
      }

      $variantBtn.removeClass("collapsed");
      if ($clickedElement.hasClass("collapsed") === false) {
        $clickedElement.addClass("collapsed");
      }
      $$(".nt-product-detail__variants.show", (el) => {
        let collapse = new Collapse(el);
        collapse.hide();
      });
    });

    // async continuation of previous event handler
    $(".nt-product-detail__variants").on("hidden.bs.collapse", () => {
      const $collapsed = $(".nt-product-detail__variant-btn.collapsed");
      let targetId = $collapsed.data("target");
      $$(targetId, (el) => {
        let collapse = new Collapse(el);
        collapse.show();
      });
      let productNbr = $collapsed.data("productNbr");
      setAddToBasketButtonVisibility(productNbr);
      setAddToBasketButtonState();
    });

    $(() => {
      let allOuterHeights = $(".js-product-specifications .nt-product-detail__variants")
        .map((index, element) => {
          return $(element).outerHeight();
        })
        .get();
      let maxOuterHeight = Math.max(...allOuterHeights);
      $(".js-product-specifications").css("min-height", maxOuterHeight + 50 + "px"); // 50 is empirical value
      if ($(".nt-product-detail__variants").length) {
        setAddToBasketButtonState();
      }
    });

    function setAddToBasketButtonVisibility(productNbrForVariantToShow: string) {
      $(".js-add-to-basket-variant").hide();
      $(`.js-add-to-basket-variant[data-product-nbr="${productNbrForVariantToShow}"]`).show();
    }

    function setAddToBasketButtonState() {
      const productNbr = $(".nt-product-detail__variant-btn.collapsed").data("product-nbr");
      const isInStock = $(`#is-in-stock-${productNbr}`).val();
      if (isInStock === "true") {
        $(".js-add-to-basket-btn").removeAttr("disabled");
      } else {
        $(".js-add-to-basket-btn").prop("disabled", true);
      }
    }
  }

  static smoothScrollTo(anchor: string, offset?: number) {
    if (anchor === "#top") {
      const properties = { scrollTop: 0 };
      $("html,body").animate(properties, 300);
      return true;
    }

    let $target = $(anchor);
    $target = ($target.length && $target) || $(`[name='${anchor.slice(1)}']`);
    if ($target.length) {
      // if we don't add extra pixel it will scroll too far because of the sticky header that overlaps the viewport
      const offsetFromTop = ($target.offset()?.top ?? 0) - (offset || 15);
      // adjust according to the design/header (if sticky header)
      ////offsetFromTop -= $("#header").height();
      ////if ($("#header").hasClass("sticky") === false) {
      ////    offsetFromTop -= 50;
      ////}
      const properties1 = { scrollTop: offsetFromTop };
      ////console.log(properties1);
      $("html,body").animate(properties1, 800);
      return true;
    }

    return false;
  }

  static initAutoLink() {
    $(".js-auto-link").each((idx: number, el: any) => {
      var a$ = $(el).find("a");
      if (a$.length !== 0) {
        $(el)
          .on("click", () => {
            var aTarget = a$.attr("target");
            if (aTarget) {
              window.open(a$.attr("href"), aTarget);
              return false;
            }

            window.document.location.href = a$.attr("href") ?? "";
            return false;
          })
          .css("cursor", "pointer");
      }
    });
  }

  private static initAutos() {
    $(".js-auto-submit").on("click", (e) => $(e.currentTarget).parents("form").trigger("submit"));

    $(".js-auto-signout").on("click", () => {
      $("#signout-form").trigger("submit");
    });

    this.initAutoLink();
  }

  private static initToggleSubmit() {
    $(".js-toggle-submit").on("click", (e) => {
      const box = $(e.currentTarget);
      const form = box.parents("form");
      if (box.is(":checked")) {
        form.find("[type=submit]").removeAttr("disabled");
        form.find("a.submit").removeAttr("disabled");
      } else {
        form.find("[type=submit]").attr("disabled", "disabled");
        form.find("a.submit").attr("disabled", "disabled");
      }
    });
  }

  private static initScrollToTop() {
    // Dynamic scroll to top button
    $(window).on("scroll", (e) => {
      if ($(e.target as any).scrollTop() ?? 0 > 300) {
        $(".nt-movetotop").fadeIn(200);
      } else {
        $(".nt-movetotop").fadeOut(200);
      }
    });
  }

  private static initCookieNotice() {
    $(".nt-cookienotice").on("click", ".btn", (e) => {
      $(e.delegateTarget).fadeOut(200);

      const expires = new Date(2050, 0, 1).toUTCString();
      document.cookie = `cookies=1;expires=${expires}`;
    });
  }

  private static mobileMenuToggle() {
    const $menu = $("#nt-header__mobile-sidebar-menu");
    // hide the sidebar
    $menu.toggleClass("active");
    // slider transition
    $menu.toggleClass("nt-header__mobile-sidebar-menu--open");
  }
}

SiteApp.init();
