import $ from "jquery";
import axios from "axios";

import * as axiosX from "./http/axios-extensions";

import Autocomplete, { AutocompleteOptions } from "./components/bootstrap-5-autocomplete";
import $$ from "./utils/double-dollar";

type SelectListItem = {
  text: string;
  value: string;
};

export default class Impersonation {
  static init() {
    Impersonation.initCustomersAutocomplete();
  }

  private static initCustomersAutocomplete() {
    let options: AutocompleteOptions = {
      source: Impersonation.getCustomers,
      onSelectItem: (item, element) => {
        const $element = $(element);
        Impersonation.log(`customer selected: ${item.value}`);
        $(".js-impersonation-select--person").text("").val("");
        Impersonation.getPersons(item.value);
        const $form = $element.parents("form");
        $form.find(".js-form-field-customer-id").val(item.value);
      },
      highlightClass: "text-danger",
      treshold: 0,
      maximumItems: 20,
    };
    $$(".js-impersonation-select--customer", (el) => new Autocomplete(el, options));
  }

  private static getCustomers() {
    const $input = $(".js-impersonation-select--customer");
    const searchTerm = $input.val();
    return axios
      .get(`/impersonation/customers?searchTerm=${searchTerm}`)
      .then((response) => response.data)
      .catch(axiosX.defaultCatch);
  }

  private static getPersons(customerId: string | number) {
    axios
      .get(`/impersonation/persons?customerId=${customerId}`)
      .then(Impersonation.fillPersons)
      .catch(axiosX.defaultCatch);
  }

  private static fillPersons(response: any) {
    let listItems: SelectListItem[] = response.data as SelectListItem[];
    let personsDictionary: any = {};
    listItems.forEach((item) => {
      personsDictionary[item.text] = item.value;
    });
    $$(
      ".js-impersonation-select--person",
      (el) =>
        new Autocomplete(el, {
          source: () => Promise.resolve(personsDictionary),
          onSelectItem: (item, element) => {
            const $element = $(element);
            Impersonation.log(`person selected: ${item.value}`);
            const $form = $element.parents("form");
            $form.find(".btn").prop("disabled", false);
            $form.find(".js-form-field-person-id").val(item.value);
          },
          highlightClass: "text-danger",
          treshold: 0,
          maximumItems: 10,
        }),
    );
  }

  private static log(msg: string) {
    console.log(msg);
  }
}
