import $ from "jquery";
import $$ from "./double-dollar";
import { Toast } from "bootstrap";

type BaseMessage = {
  title?: string;
  message: string;
};

export type SuccesMessage = BaseMessage & {
  type: "success";
};

export type WarningMessage = BaseMessage & {
  type: "warning";
};

export type ErrorMessage = BaseMessage & {
  type: "error";
};

export type ToastMessage = SuccesMessage | WarningMessage | ErrorMessage;

export type TemplateMessage = {
  toastTemplateSelector: string;
  extraTitle?: string;
  extraBodyMessage?: string;
  /** Auto hide the toast, default: true */
  autohide?: boolean;
  /** Delay hiding the toast (ms), default: 5000ms */
  delay?: number;
};

// export class Toaster {

export function pop(toast: ToastMessage | TemplateMessage) {
  if ("toastTemplateSelector" in toast) {
    popTemplate(toast);
  } else {
    popToast(toast);
  }
}

function popToast(toast: ToastMessage): void {
  switch (toast.type) {
    case "success":
      return popTemplate({
        toastTemplateSelector: ".nt-toast--generic.nt-toast--success",
        extraTitle: toast.title,
        extraBodyMessage: toast.message,
        autohide: true,
      });
    case "warning":
      return popTemplate({
        toastTemplateSelector: ".nt-toast--generic.nt-toast--warning",
        extraTitle: toast.title,
        extraBodyMessage: toast.message,
        autohide: true,
        delay: 10000,
      });
    case "error":
      return popTemplate({
        toastTemplateSelector: ".nt-toast--generic.nt-toast--error",
        extraTitle: toast.title,
        extraBodyMessage: toast.message,
        autohide: false,
      });
  }
}

function popTemplate(message: TemplateMessage): void {
  message.autohide = message.autohide ?? true;
  message.delay = message.delay ?? 5000;
  let toastSelector = `.nt-toaster .toast${message.toastTemplateSelector}`;
  let toastTemplateSelector = `.nt-toaster-templates .toast${message.toastTemplateSelector}`;
  let $template = $(`${toastTemplateSelector}:first`);
  let $copy = $template.clone();
  if (message.extraTitle) {
    $copy.find(".nt-toast-title").append(message.extraTitle);
  }
  if (message.extraBodyMessage) {
    $copy.children(".toast-body").append(message.extraBodyMessage);
  }
  $(".nt-toaster").append($copy);
  $$(`${toastSelector}`, (el) => {
    let toast = new Toast(el, { autohide: message.autohide, delay: message.delay });
    toast.show();
    el.addEventListener("hidden.bs.toast", function () {
      el.classList.add("d-none");
    });
  });
}
