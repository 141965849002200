import $ from "jquery";

export default class CheckoutDetails {
  static init() {
    // delivery address changed -> show address details
    function setDeliveryAddressesVisibility($select: JQuery<HTMLElement>) {
      if ($select.val() === "") {
        return;
      }
      let $address = $(`div[data-address-id=${$select.val()}]`);
      $select.siblings("div").hide();
      $address.show();
    }

    $(".nt-delivery-address-selector").on("change", "select#Form_DeliveryAddressId", (e) => {
      setDeliveryAddressesVisibility($(e.currentTarget));
    });
    setDeliveryAddressesVisibility($("select#Form_DeliveryAddressId"));

    // choice delivery address known vs alternate
    function setChosenVisibility($check: JQuery<HTMLElement>) {
      if ($check.val() === "true") {
        $(".js-show-when-choosing-known-delivery-address").show();
        $(".js-show-when-choosing-alternate-delivery-address").hide();
      } else {
        $(".js-show-when-choosing-known-delivery-address").hide();
        $(".js-show-when-choosing-alternate-delivery-address").show();
      }
    }

    $(".nt-delivery-address-selector").on("change", `[name="Form.ChooseKnownDeliveryAddress"]`, (e) => {
      const $e = $(e.currentTarget);
      setChosenVisibility($e);
    });
    setChosenVisibility($(`.nt-delivery-address-selector [name="Form.ChooseKnownDeliveryAddress"]:checked`));
  }
}

CheckoutDetails.init();
