import $ from "jquery";
import { Popover } from "bootstrap";

export default class StyleGuide {
  static init() {
    // part of styleguide
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new Popover(popoverTriggerEl);
    });

    $(".bs-component").each((idx, el) => {
      const $button = $(
        "<button class='source-button btn btn-primary btn-xs' role='button' tabindex='0'>&lt; &gt;</button>",
      );
      $(el).append($button);
    });

    $("body").on("click", ".source-button", (e) => {
      e.preventDefault();

      let html = $(e.target).parent().html();
      html = this.cleanSource(html);
      $("#source-modal pre").text(html);
      $("#source-modal").modal();
    });
  }

  private static cleanSource(html: string) {
    // part of styleguide
    html = html
      .replace(/×/g, "&times;")
      .replace(/«/g, "&laquo;")
      .replace(/»/g, "&raquo;")
      .replace(/←/g, "&larr;")
      .replace(/→/g, "&rarr;");

    let lines = html.split(/\n/);

    lines.shift();
    lines.splice(-1, 1);

    const indentSize = lines[0].length - lines[0].trim().length;
    const re = new RegExp(` {${indentSize}}`);

    lines = lines.map((line: string) => {
      if (line.match(re)) {
        line = line.substring(indentSize);
      }

      return line;
    });

    const result = lines.join("\n");

    return result;
  }
}
