import $ from "jquery";
import $$ from "../utils/double-dollar";
import { Collapse } from "bootstrap";

import BasketComponent from "./basket";
import type { BasketContent, BasketLineQuantityUpdateResponse, BasketUpdateLineRequestDto, Guid } from "./types";
import { isChangeLineQuantityResponse } from "./type-guards";
import * as Toaster from "../utils/toaster";

export class BasketTemplateComponent extends BasketComponent {
  constructor(elementSelector: string) {
    super(elementSelector);
  }

  protected getReloadQueryString(basketUniqueId: Guid): string | undefined {
    return $.param({ bid: basketUniqueId });
  }

  protected changeBasketLineQuantity(el: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    let promise = super.changeBasketLineQuantity(el, sendData);
    promise.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;
      if (isChangeLineQuantityResponse(data)) {
        Toaster.pop({
          toastTemplateSelector: ".nt-toast--templateupdated.nt-toast--success",
        });
      }
    });
    return promise;
  }

  protected removeBasketLine(el: HTMLElement) {
    let promise = super.removeBasketLine(el);
    promise.then((response: any) => {
      Toaster.pop({
        toastTemplateSelector: ".nt-toast--removedfromtemplate.nt-toast--success",
      });
      this.updateTemplateBasketLines(response.data.updatedBasket);
    });
    return promise;
  }

  private updateTemplateBasketLines(updatedBasket: BasketContent) {
    let updatedNumberOfLines = updatedBasket.basketLines.length;
    let basketUniqueId = updatedBasket.basketUniqueId;
    $(`.js-basket-template[data-bid="${basketUniqueId}"]`)
      .find(".js-basket-template-number-of-lines")
      .html(updatedNumberOfLines.toString());
  }
}

new BasketTemplateComponent(".nt-basket-view.nt-basket-view--template");

$(() => {
  if ($(".nt-basket-templates-overview").length > 0) {
    const bid = new URL(location.href).searchParams.get("bid");
    $$(`#basket_${bid}`, (el) => {
      let collapse = new Collapse(el);
      collapse.show();
    });
  }
});
