import $ from "jquery";
import { addMonths, format } from "date-fns";

export class OrderHistoryResetFilters {
  constructor() {
    $("form").on("click", ".js-order-history--reset-filters", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $resetBtn = $(e.currentTarget);
      const $form = $(e.delegateTarget);
      $form.find("input,select").each((index, element) => {
        const $element = $(element);
        const defaultValue = $element.data("defaultValue");
        $element.val(defaultValue);
      });
    });
  }
}

export class OrderHistoryDetailOpener {
  constructor() {
    $(".js-order-history--go-to-order").on("click", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $row = $(e.currentTarget);
      document.location = $row.data("orderUri");
    });
  }
}

export class OrderHistoryDateFilterSanitizer {
  constructor() {
    const maxMonthsInRange = 3;
    $("form").on("change", ".js-order-history--from-date", (e) => {
      const range = this.getSelectedRange();
      if (this.isValidRange(range) === false) {
        this.setUntilDate(addMonths(range.start, maxMonthsInRange));
        return;
      }
      const threeMonthsAfterFrom = addMonths(range.start, maxMonthsInRange);
      if (threeMonthsAfterFrom < range.end) {
        this.setUntilDate(threeMonthsAfterFrom);
        return;
      }
    });
    $("form").on("change", ".js-order-history--until-date", (e) => {
      const range = this.getSelectedRange();
      if (this.isValidRange(range) === false) {
        this.setFromDate(addMonths(range.end, -maxMonthsInRange));
        return;
      }
      const threeMonthsBeforeUntil = addMonths(range.end, -maxMonthsInRange);
      if (range.start < threeMonthsBeforeUntil) {
        this.setFromDate(threeMonthsBeforeUntil);
        return;
      }
    });
  }

  private isValidRange(range: Interval) {
    return range.start <= range.end;
  }

  private yyyMMdd(date: Date | number) {
    return format(date, "yyyy-MM-dd");
  }

  private setFromDate(value: Date | number) {
    $(".js-order-history--from-date").val(this.yyyMMdd(value));
  }

  private setUntilDate(value: Date | number) {
    $(".js-order-history--until-date").val(this.yyyMMdd(value));
  }

  private getSelectedRange(): Interval {
    const from = $(".js-order-history--from-date").val()?.toString() ?? "";
    const until = $(".js-order-history--until-date").val()?.toString() ?? "";
    return { start: new Date(from), end: new Date(until) };
  }
}

new OrderHistoryResetFilters();
new OrderHistoryDetailOpener();
new OrderHistoryDateFilterSanitizer();
