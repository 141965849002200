import $ from "jquery";

import BasketComponent from "./basket";
import * as Toaster from "../utils/toaster";
import MiniBasket from "./mini-basket";
import type {
  BasketContent,
  BasketUpdateLineRequestDto,
  ChangeLineQuantityResponse,
  InsufficientStockDetectedDto,
} from "./types";
// import OpenInsuffienctStockModalFactory from "./insufficient-stock-modal";
// import AddToBasketComponent from "./add-to-basket"
import NatchGtm from "natch-gtm";

type BasketLineQuantityUpdateResponse =
  | ChangeLineQuantityResponse
  | InsufficientStockDetectedDto<BasketUpdateLineRequestDto>;

function isChangeLineQuantityResponse(
  response: BasketLineQuantityUpdateResponse,
): response is ChangeLineQuantityResponse {
  return (response as ChangeLineQuantityResponse).quantity !== undefined;
}

function isInsufficientStockDetectedDto(
  response: BasketLineQuantityUpdateResponse,
): response is InsufficientStockDetectedDto<BasketUpdateLineRequestDto> {
  return (response as InsufficientStockDetectedDto<BasketUpdateLineRequestDto>).originalRequest !== undefined;
}

export class ShoppingBasketComponent extends BasketComponent {
  private natchGtm = new NatchGtm();

  constructor(elementSelector: string) {
    super(elementSelector);
    $(elementSelector).on("click", ".nt-scroll-to-basket-line", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.scrollToBasketLine($(e.currentTarget).data("lineGroupGuid"));
    });
  }

  protected changeBasketLineQuantity(el?: HTMLElement, sendData?: BasketUpdateLineRequestDto) {
    let promise = super.changeBasketLineQuantity(el, sendData);
    let self = this;
    promise.then((response: any) => {
      const data = response.data as BasketLineQuantityUpdateResponse;
      if (isChangeLineQuantityResponse(data)) {
        Toaster.pop({
          toastTemplateSelector: ".nt-toast--basketupdated.nt-toast--success",
        });
        this.fillInTotals(data.updatedBasket);
        MiniBasket.update();
      }
    });
    return promise;
  }

  protected removeBasketLine(el: HTMLElement) {
    const self = this;
    const $this = $(el);

    // get GTM datalayer data in a safe way
    const jsonString = $this.parents("[data-datalayer-cart-item]").attr("data-datalayer-cart-item");
    // console.log(jsonString);
    const dataLayerProduct = jsonString ? JSON.parse(jsonString) : null;
    // console.log(dataLayerProduct);

    let promise = super.removeBasketLine(el);
    promise.then((response: any) => {
      Toaster.pop({
        toastTemplateSelector: ".nt-toast--removedfrombasket.nt-toast--success",
      });
      this.fillInTotals(response.data.updatedBasket);
      MiniBasket.update();

      if (dataLayerProduct !== null) {
        ////console.log("Calling natchGtm.trackRemoveFromCart", dataLayerProduct);
        self.natchGtm.trackRemoveFromCart(dataLayerProduct);
      }

      if ($(".nt-alert--unavailable-products").children(".nt-basketline").length === 0) {
        $(".nt-alert--unavailable-products").hide();
      }
      $(".nt-btn-continue").prop("disabled", response.data.updatedBasket.canContinue === false);
    });
    return promise;
  }

  private fillInTotals(updatedBasket: BasketContent) {
    $(".js-basket-updateable-sub-total").text(updatedBasket.subTotalFormatted);
    for (let productSurchargeTotal of updatedBasket.productSurchargeTotals) {
      let selector = `.js-basket-updateable-surcharge-total[data-product-surcharge-type-id="${productSurchargeTotal.productSurchargeType.id}"]`;
      $(selector).text(productSurchargeTotal.amountFormatted);
    }
    $(".js-basket-updateable-total").text(updatedBasket.totalFormatted);
  }

  private scrollToBasketLine(lineGroupGuid: string) {
    const $line = $(`#${lineGroupGuid}`);
    if ($line.length === 0) return;
    $("html").animate(
      {
        scrollTop: $line.offset()?.top,
      },
      {
        duration: 1000,
      },
    );
  }
}

new ShoppingBasketComponent(".nt-basket-view.nt-basket-view--shopping-basket");
