import $ from "jquery";
import { Modal } from "bootstrap";

import type { BasketAddRequestDto, Guid, IBasketLineRequest, InsufficientStockDetectedDto } from "./types";
import $$ from "../utils/double-dollar";

const insufficientStockModalSelector = "#insufficient-stock-modal";

let modal: Modal | undefined = undefined;
export default class OpenInsufficientStockModalFactory {
  public static create<TOriginalRequest extends IBasketLineRequest>(
    sendOriginalRequest: (data: TOriginalRequest) => Promise<any>,
    sendReplacementRequest: (data: BasketAddRequestDto) => Promise<any>,
    sendRemoveOriginalRequest: (data: TOriginalRequest) => Promise<any>,
    createOriginalRequest: (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
      lineGroupGuid?: Guid,
    ) => TOriginalRequest,
    createReplacementRequest: (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
    ) => BasketAddRequestDto,
    onCancelAction?: () => void,
  ) {
    return function openInsufficientStockModal(data: InsufficientStockDetectedDto<TOriginalRequest>) {
      const $modal = $(insufficientStockModalSelector);
      $$(insufficientStockModalSelector, (el) => {
        modal = new Modal(el);
      });

      const template = $modal.find(".js-template").html();
      let html = template
        .replace(
          /\{originalProductName\}/g,
          `<span class="nt-insufficient-stock-product-name--original">${data.originalProductName}</span>`,
        )
        .replace(
          /\{replacementProductName\}/g,
          `<a href="${data.replacementProduct?.uri}" target="_blank" class="nt-insufficient-stock-product-name--replacement">${data.replacementProduct?.name}</a>`,
        )
        .replace(/\{originalProductStockcount\}/g, data.originalProductStockCount.toString());
      $(".js-replaced").html(html);
      if (!!data.replacementProduct) {
        $(".js-show-only-when-replacement-suggested").show();
        $(".js-show-only-when-no-replacement-suggested").hide();
        $(".js-show-only-when-stock-depleted-and-no-replacement").hide();
        if (data.originalProductStockCount === 0) {
          $(".js-show-only-when-stock-available").hide();
        } else {
          $(".js-show-only-when-stock-available").show();
        }
      } else {
        $(".js-show-only-when-replacement-suggested").hide();
        $(".js-show-only-when-no-replacement-suggested").show();
        if (data.originalProductStockCount === 0) {
          $(".js-show-only-when-stock-depleted-and-no-replacement").show();
          $(".js-show-only-when-stock-available").hide();
        } else {
          $(".js-show-only-when-stock-depleted-and-no-replacement").hide();
          $(".js-show-only-when-stock-available").show();
        }
      }

      function modalClickHandlerWrapper(e: JQuery.ClickEvent, action: () => void) {
        e.preventDefault();
        e.stopImmediatePropagation();
        action();
        $modal.off("click");
        modal?.hide();
        $(".js-confirm").prop("disabled", true);
      }

      function triggerChangeEvent(originalRequest: TOriginalRequest) {
        const pid = originalRequest.encryptedProductId;
        const bid = originalRequest.basketUniqueId;
        $(document).trigger("basketLine:conflictResolved", [pid, bid]);
      }

      function getConfirmAction(radioValue?: string) {
        let confirmAction: () => void;
        switch (radioValue) {
          case "order-remaining-stock-of-original":
            confirmAction = () => {
              const sendData: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                data.originalProductStockCount,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );
              sendOriginalRequest(sendData).then(() => triggerChangeEvent(data.originalRequest));
            };
            break;
          case "order-replacement":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                0,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );
              const replacementOrder: BasketAddRequestDto = createReplacementRequest(
                data.replacementProduct?.encryptedProductId,
                data.originalRequest.quantity,
                data.originalRequest.basketUniqueId,
                data.replacementProduct,
              );
              sendReplacementRequest(replacementOrder)
                .then(() => sendRemoveOriginalRequest(originalOrder))
                .then(() => triggerChangeEvent(data.originalRequest));
            };
            break;
          case "order-stock-of-original-and-supplement-with-replacement":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                data.originalProductStockCount,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );
              const replacementOrder: BasketAddRequestDto = createReplacementRequest(
                data.replacementProduct?.encryptedProductId,
                data.originalRequest.quantity - data.originalProductStockCount,
                data.originalRequest.basketUniqueId,
                data.replacementProduct,
              );
              // our basket service is not 'thread-safe'/can't handle concurrent requests
              sendReplacementRequest(replacementOrder)
                .then(() => sendOriginalRequest(originalOrder))
                .then(() => triggerChangeEvent(data.originalRequest));
            };
            break;
          case "remove-original":
            confirmAction = () => {
              const originalOrder: TOriginalRequest = createOriginalRequest(
                data.originalRequest.encryptedProductId,
                0,
                data.originalRequest.basketUniqueId,
                data.originalRequest.dataLayerProduct,
                data.originalRequest.lineGroupGuid,
              );
              sendRemoveOriginalRequest(originalOrder).then(() => triggerChangeEvent(data.originalRequest));
            };
            break;
          default:
            console.warn(`No action for selected option: ${radioValue}`);
            confirmAction = () => {};
            break;
        }
        return confirmAction;
      }

      $modal.on("click", ".js-confirm", (e) => {
        modalClickHandlerWrapper(e, () => {
          const radioValue = $(`input[type="radio"][name="conflict-resolution-option"]:checked`).val()?.toString();
          let confirmAction: () => void = getConfirmAction(radioValue);
          confirmAction();
        });
      });

      $modal.on("click", ".js-cancel", (e) => {
        modalClickHandlerWrapper(e, () => {
          if (!!onCancelAction) {
            onCancelAction();
          }
        });
      });

      $modal.on("click", `input[type="radio"][name="conflict-resolution-option"]`, () => {
        $(".js-confirm").prop("disabled", false);
      });

      modal?.show();
    };
  }
}
