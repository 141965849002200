import $ from "jquery";
import axios from "axios";

import NatchGtm from "natch-gtm";
import * as Toaster from "../utils/toaster";
import MiniBasket from "./mini-basket";
import * as axiosX from "../http/axios-extensions";
import SafeJSON from "../utils/safe-json";
import type { BasketAddRequestDto, BasketAddResponseDto, Guid, InsufficientStockDetectedDto } from "./types";
import { isAddedToBasketDto, isInsufficientStockDetectedDtoOfTypeBasketAddRequestDto } from "./type-guards";
import OpenInsufficientStockModalFactory from "./insufficient-stock-modal";
import QuantitySanitizer from "./quantity-sanitizer";
import eventBus from "../vuetils/event-bus";

const addToBasketEvent = new Event("add-to-basket");

/**
 * Provides event listeners & handlers for
 * adding a product+quantity to a shopping basket.
 */
export default class AddToBasketComponent {
  private static natchGtm = new NatchGtm();
  public readonly quantitySanitizer = new QuantitySanitizer();
  public elementSelector: string;

  constructor(elementSelector: string) {
    this.elementSelector = elementSelector;
    $(elementSelector).on("submit", (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      this.submitAddToBasketForm(e.target);
    });
    $(elementSelector).on("click", `input[name="quantity"]`, (e) => {
      const $element = $(e.currentTarget);
      $element.trigger("select");
    });
  }

  public static addToBasket(sendData: BasketAddRequestDto) {
    const posting = axios.post("/basket/add", sendData);
    posting.then((response: any) => {
      const data: BasketAddResponseDto = response.data;
      if (isAddedToBasketDto(data)) {
        Toaster.pop({
          toastTemplateSelector: ".nt-toast--addedtobasket.nt-toast--success",
          extraBodyMessage: `${data.productName}<br>${data.quantity} ${data.unitOfMeasure}`,
        });
        MiniBasket.update();
        eventBus.emit("basket-updated");
        eventBus.emit("basket-line-updated", { quantity: data.quantity, lineGroupGuid: data.lineGroupGuid });
        if (!!sendData.dataLayerProduct) {
          ////console.log("Calling natchGtm.trackAddToCart", dataLayerProduct);
          AddToBasketComponent.natchGtm.trackAddToCart(sendData.dataLayerProduct, data.quantity);
        } else {
          console.warn("'dataLayerProduct' is empty!");
        }
      } else if (isInsufficientStockDetectedDtoOfTypeBasketAddRequestDto(data)) {
        this.openInsufficientStockModal(data);
      }
    });

    posting.catch(axiosX.defaultCatch);

    return posting;
  }

  private static openInsufficientStockModal(data: InsufficientStockDetectedDto<BasketAddRequestDto>) {
    const createRequest = (
      encryptedProductId: string | undefined,
      quantity: number,
      basketUniqueId: Guid,
      dataLayerProduct: any,
    ) => {
      return {
        encryptedProductId: encryptedProductId,
        quantity: quantity,
        dataLayerProduct: dataLayerProduct,
        basketUniqueId: basketUniqueId,
      };
    };

    let openModal = OpenInsufficientStockModalFactory.create<BasketAddRequestDto>(
      this.addToBasket,
      this.addToBasket,
      (data) => Promise.resolve(data),
      createRequest,
      createRequest,
    );
    return openModal(data);
  }

  public submitAddToBasketForm(el: HTMLElement) {
    const form$ = $(el);
    $(form$).removeClass("input-validation-error");
    const $input = $('[name="quantity"]', form$);
    const quantityValue = $input.val() as string;
    if (quantityValue === "" || quantityValue === "0") {
      return;
    }
    if (!form$.valid()) {
      console.log("Quantity not valid.");
      $(form$).addClass("input-validation-error");
      return;
    }
    const min = parseInt($input.prop("min")); // MOQ
    const step = parseInt($input.prop("step")); // IOQ

    const encryptedProductId = form$.data("pid");
    const basketUniqueId = form$.data("bid");
    const quantity = this.quantitySanitizer.sanitize(parseInt(quantityValue), min, step);

    if (typeof encryptedProductId === "undefined") {
      return;
    }

    // get GTM datalayer data in a safe way
    const jsonString = form$.attr("data-datalayer-cart-item");
    const dataLayerProduct = SafeJSON.parse(jsonString);
    const sendData: BasketAddRequestDto = {
      encryptedProductId: encryptedProductId,
      quantity: quantity,
      dataLayerProduct: dataLayerProduct,
      basketUniqueId: basketUniqueId,
    };

    $("input", form$).prop("readonly", true);

    const posting = AddToBasketComponent.addToBasket(sendData);

    posting.finally(() => {
      // submit button changed by handler in Forms.init()
      const $submitBtn = form$.find("[type=submit]");
      $submitBtn.prop("disabled", false);
      $submitBtn.html($submitBtn.data("originalhtml"));
      $("input", form$).prop("readonly", false);
      el.dispatchEvent(addToBasketEvent);
    });
  }
}

class AddAllToBasketComponent {
  constructor(elementSelector: string, addToBasket: AddToBasketComponent) {
    const formsSelector = addToBasket.elementSelector;
    $(elementSelector).on("click", (e) => {
      $(formsSelector).trigger("submit");
    });
    $(elementSelector).on("keypress", (e) => {
      const event = e.originalEvent;
      const keycode = event?.keyCode ?? event?.which ?? 0;
      if (keycode === 13) {
        $(formsSelector).trigger("submit");
      }
    });
  }
}

const addToBasket = new AddToBasketComponent("form.nt-add-to-basket");
new AddAllToBasketComponent(".js-add-all-to-basket", addToBasket);
