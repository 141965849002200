interface Array<T> {
  unique(): Array<T>;

  distinct(): Array<T>;
}

Array.prototype.unique = function () {
  return [...new Set(this)];
};

Array.prototype.distinct = Array.prototype.unique;
