import $ from "jquery";
import { Button, Tooltip } from "bootstrap";

import $$ from "./script/utils/double-dollar";

import "./script/site";

import "./script/http/set-axios-defaults";
import Styleguide from "./script/style-guide";
import ToggleSubmit from "./script/utils/toggle-submit";
import Forms from "./script/utils/forms";
import SearchPage from "./script/search-page";
import CheckoutDetails from "./script/checkout-details";
import NatchGtm from "natch-gtm";
import ProductFavoritizer from "./script/product-favoritizer";
import ProductPricing from "./script/product-pricing";
import BasketTemplateInteractions from "./script/baskets/basket-template-interactions";
import Impersonation from "./script/impersonation";

import "./script/baskets/shopping-basket";
import "./script/baskets/basket-template";
import "./script/baskets/add-to-basket";

import "./script/order-history";
import "./script/product-detail";
import "./script/product-list";
import "./script/product-quick-entry";
import "./script/checkout-content";

/* this declaration in /clientapp/types/static.d.ts isn't picked up, so putting it here */
declare global {
  interface Window {
    $: JQueryStatic;
    jQuery: JQueryStatic;
  }
}

// need jQuery on the window object for jquery validation
window.jQuery = $;

class App {
  static init() {
    const natchGtm = new NatchGtm();
    natchGtm.processPage();

    $(".hide.onload").hide().removeClass("hide").removeClass("onload");

    // initialize Bootstrap components
    $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));
    $$(`[data-bs-toggle="button"]`, (el) => new Button(el));

    Styleguide.init();
    ToggleSubmit.init();
    Forms.init();
    ProductFavoritizer.init();
    ProductPricing.init();
    BasketTemplateInteractions.init();
    Impersonation.init();

    var searchPage = new SearchPage(
      "#product-search-bar",
      "#paged-product-search-results",
      ".js-request-page-links-via-xhr.pagination",
      [".js-product-search-summary"],
    );
    searchPage.init();

    CheckoutDetails.init();

    console.log("🚀 App initialized");
  }
}

App.init();
