import $ from "jquery";
import { Carousel } from "bootstrap";

class ProductDetail {
  constructor() {
    this.addEventListeners();
    // ProductPricing.init();
  }

  private addEventListeners() {
    $(() => {
      // 'Kenmerken' - 'Toon meer'
      $(".nt-show-more-props__btn-block").on("click", () => {
        if ($(".js-show-more-props__block").data("more") === 1) {
          $(".js-show-more-props__block")
            .removeClass("nt-show-more-props__block--closed")
            .addClass("nt-show-more-props__block--opened")
            .data("more", 0);
          $(".nt-show-more-props__btn")
            .removeClass("nt-show-more-props__btn--closed")
            .addClass("nt-show-more-props__btn--opened")
            .data("moreClosed", 0);
          const translatedOpenedText = $(".nt-show-more-props__btn").data("translationOpened");
          $(".nt-show-more-props__btn").text(translatedOpenedText);
        } else {
          $(".js-show-more-props__block")
            .removeClass("nt-show-more-props__block--opened")
            .addClass("nt-show-more-props__block--closed")
            .data("more", 1);
          $(".nt-show-more-props__btn")
            .removeClass("nt-show-more-props__btn--opened")
            .addClass("nt-show-more-props__btn--closed")
            .data("moreClosed", 1);
          const translatedClosedText = $(".nt-show-more-props__btn").data("translationClosed");
          $(".nt-show-more-props__btn").text(translatedClosedText);
        }
      });

      const carouselElement = document.getElementById("carousel-thumb");
      const carousel = !!carouselElement ? new Carousel(carouselElement) : null;

      // product image carousel
      $(".nt-carousel-image-bbox").first().addClass("active");

      $(".nt-carousel-image-bbox:not(.active)").on("click", (e) => {
        var $this = $(e.currentTarget);
        var nextSlide = $this.data("bsSlideTo") as number;
        carousel?.to(nextSlide);
      });

      carouselElement?.addEventListener("slide.bs.carousel", (e: any) => {
        $(".nt-carousel-image-bbox").removeClass("active");
        $(`.nt-carousel-image-bbox[data-bs-slide-to="${e.to}"`).addClass("active");
      });
    });
  }
}

var pd = new ProductDetail();
